<template>
    <div class="card-tail">
        <div>
            <el-form v-loading="$waiting.is('loading')">
                <div class="p-5">
                    <header class="text-header-blue text-xl font-semibold mb-8">Förvaltningsberättelse</header>
                    <div v-if="annualReportData">
                        <!-- Årsredovisningen i SEK -->
                        <AnnualReportSekCard
                            @create="createInfo()"
                            @close="closeCard"
                            title="Årsredovisningen i SEK"
                            :toggle-value="annualReportData.addAnnualReportIsSek"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.annualReportSek"
                            @update:text="annualReportData.annualReportSek = $event"
                        />
                        <!-- Allmänt om verksamheten -->
                        <GeneralBusinessInfoCard
                            @create="createInfo()"
                            @close="closeCard"
                            title="Allmänt om verksamheten"
                            :toggle-value="annualReportData.addGeneralBusinessInfo"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.generalBusinessInfo"
                            @update:text="annualReportData.generalBusinessInfo = $event"
                            :annual-report-id="annualReportId"
                        />
                        <!-- Säte -->
                        <ResidenceInfoCard
                            @create="createInfo()"
                            @close="closeCard"
                            title="Säte"
                            :toggle-value="annualReportData.addResidenceInfo"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.residenceInfo"
                            @update:text="annualReportData.residenceInfo = $event"
                            :annual-report-id="annualReportId"
                        />
                        <!-- Väsentliga händelser under räkenskapsåret -->
                        <FinancialYearEventCard
                            @create="createInfo()"
                            @close="closeCard"
                            title="Väsentliga händelser under räkenskapsåret"
                            :toggle-value="annualReportData.addFinancialYearEventTitle"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.financialYearEventInfo"
                            @update:text="annualReportData.financialYearEventInfo = $event"
                        />
                        <!-- Flerårsöversikt -->
                        <div v-if="annualReportData.yearlyOverview">
                            <MultiYearCard
                                @create="createInfo()"
                                @close="closeCard"
                                title="Flerårsöversikt"
                                :toggle-value="annualReportData.addYearlyOverview"
                                @toggle-change="handleToggleChange"
                                :input-text="annualReportData.yearlyOverview.yearlyOverviewComment"
                                @update:text="annualReportData.yearlyOverview.yearlyOverviewComment = $event"
                                :yearly-overview-data="annualReportData.yearlyOverview"
                                @update-yearly-overview-data="updatedYearlyOverviewData = $event"
                                :show-comment="annualReportData.yearlyOverview.showYearlyOverviewComment"
                                @toggle-comment="annualReportData.yearlyOverview.showYearlyOverviewComment = $event"
                                @update:showYearlyOverviewComment="annualReportData.yearlyOverview.showYearlyOverviewComment = $event"
                                :annual-report-id="annualReportId"
                                @update:row="annualReportData.yearlyOverview = $event"
                            />
                        </div>
                        <!-- Förändringar i eget kapital -->
                        <div v-if="annualReportData.changesInEquity">
                            <ChangeInEquityCard
                                @create="createInfo()"
                                @close="closeCard"
                                :annual-report-id="annualReportId"
                                title="Förändringar i eget kapital"
                                :toggle-value="annualReportData.addChangesInEquity"
                                @toggle-change="handleToggleChange"
                                :change-in-equity-data="annualReportData.changesInEquity"
                                :row-data="annualReportData.changesInEquity.rows"
                                @update:row="annualReportData.changesInEquity.rows = $event"
                                :column-data="annualReportData.changesInEquity.columnTitles"
                                @update:column="annualReportData.changesInEquity.columnTitles = $event"
                                :input-text="annualReportData.changesInEquity.changesInEquityComment"
                                @update:text="annualReportData.changesInEquity.changesInEquityComment = $event"
                                :show-comment="annualReportData.changesInEquity.showChangesInEquityComment"
                                @toggle-comment="annualReportData.changesInEquity.showChangesInEquityComment = $event"
                                @update:showChangesInEquityComment="annualReportData.changesInEquity.showChangesInEquityComment = $event"
                            />
                        </div>
                        <!-- Resultatdisposition -->
                        <div v-if="annualReportData.incomeStatementAndProfitDistributions">
                            <ProfitDispositionCard
                                @create="createInfo()"
                                @close="closeCard"
                                title="Resultatdisposition"
                                :toggle-value="annualReportData.addIncomeStatementAndProfitDistribution"
                                @toggle-change="handleToggleChange"
                                :table-data="annualReportData.incomeStatementAndProfitDistributions"
                                @update-table-data="updateTableData = $event"
                                :input-text="annualReportData.incomeStatementAndProfitDistributions.statementFromBoard"
                                @update:text="annualReportData.incomeStatementAndProfitDistributions.statementFromBoard = $event"
                                :show-comment="annualReportData.incomeStatementAndProfitDistributions.showStatementFromBoard"
                                @toggle-comment="annualReportData.incomeStatementAndProfitDistributions.showStatementFromBoard = $event"
                                @update:showStatementFromBoard="annualReportData.incomeStatementAndProfitDistributions.showStatementFromBoard = $event"
                                @update:profitDistributions="annualReportData.incomeStatementAndProfitDistributions = $event"
                                :annual-report-id="annualReportId"
                            />
                        </div>
                        <!-- Avslut - Företagets finansiella resultat och ställning -->
                        <Completion
                            @create="createInfo()"
                            @close="closeCard"
                            title="Avslut"
                            :toggle-value="annualReportData.addCompletion"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.completion"
                            @update:text="annualReportData.completion = $event"
                        />

                        <!-- Modal för utkast -->
                        <ManagementReportPdfCard
                            ref="inspectManagementReportModal"
                            :client-info="clientInfo"
                            :visible="draftVisible"
                            @close="draftVisible = false"
                            :annual-report-data="annualReportData"
                        />
                    </div>
                </div>

                <div class="p-5 flex justify-end w-full">
                    <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
                    <el-button size="medium" type="warning" @click="exportDraftPdf">Utkast Årsredovisning</el-button>
                    <el-button size="medium" class="button is-info" @click="showDraft">Granska</el-button>
                    <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Api from "../annualReport.api";
export default {
    props: {
        clientInfo: {
            type: Object,
        },
    },
    components: {
        AnnualReportSekCard: () => import("./AnnualReportSek.vue"),
        GeneralBusinessInfoCard: () => import("./GeneralBusinessInfo.vue"),
        ResidenceInfoCard: () => import("./ResidenceInfo.vue"),
        FinancialYearEventCard: () => import("./FinancialYearEventInfo.vue"),
        MultiYearCard: () => import("./MultiYearOverview.vue"),
        ChangeInEquityCard: () => import("./ChangeInEquity.vue"),
        ProfitDispositionCard: () => import("./ProfitDisposition.vue"),
        ManagementReportPdfCard: () => import("./ManagementReportDraft.vue"),
        Completion: () => import("./Completion.vue"),
    },
    data() {
        return {
            annualReportId: undefined,
            draftVisible: false,
            annualReportData: {},
            oldInputText: "",
        };
    },
    async created() {
        this.$waiting.start("loading");
        this.$waiting.start("loading-multiYearOverView");
        this.$waiting.start("loading-changeInEquity");
        this.$waiting.start("loading-profitDisposition");
        this.annualReportId = this.$route.params.annualReportId;
        this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
        if (this.annualReportData == "" || this.annualReportData == null) {
            this.annualReportData = await Api.createManagementReport(this.annualReportId);
            this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
        }
        this.setOldValues();
        this.$waiting.end("loading");

        await this.getValidatedMultiYearOverview();
        await this.getValidatedChangesInEquity();
        await this.getValidatedProfitDisposition();
        // load result disposition data
        //api to get validated result disposition data
    },
    methods: {
        setOldValues() {
            this.oldInputText = this.annualReportData.generalBusinessInfo;
        },
        async goToNextPage() {
            await this.$router.push(`/annualReport/incomeStatement/${this.annualReportId}`);
        },
        async goToPageBefore() {
            await this.$router.push(`/annualReport/companyInformation/${this.annualReportId}`);
        },
        showDraft() {
            this.draftVisible = true;
            this.$nextTick(() => {
                this.$refs.inspectManagementReportModal.$el.focus();
            });
        },
        exportDraftPdf() {
            console.log("Exporting draft pdf");
        },
        async handleToggleChange(eventData) {
            if (eventData.source === 1) {
                this.annualReportData.addAnnualReportIsSek = eventData.value;
            }
            if (eventData.source === 2) {
                this.annualReportData.addGeneralBusinessInfo = eventData.value;
            }
            if (eventData.source === 3) {
                this.annualReportData.addResidenceInfo = eventData.value;
            }
            if (eventData.source === 4) {
                this.annualReportData.addFinancialYearEventTitle = eventData.value;
            }
            if (eventData.source === 5) {
                this.annualReportData.addYearlyOverview = eventData.value;
            }
            if (eventData.source === 6) {
                this.annualReportData.addChangesInEquity = eventData.value;
            }
            if (eventData.source === 7) {
                this.annualReportData.addIncomeStatementAndProfitDistribution = eventData.value;
            }
            if (eventData.source === 8) {
                this.annualReportData.addCompletion = eventData.value;
            }
            await Api.updateManagementReportDetails(this.annualReportId, this.annualReportData);
        },
        async createInfo() {
            await Api.updateManagementReportDetails(this.annualReportId, this.annualReportData);
        },
        async closeCard(value = {}) {
            if (value.inputTextNewValue) {
                this.$waiting.start(value.load);
                this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
                this.$waiting.end(value.load);
            } else {
                this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
            }
        },
        async getValidatedMultiYearOverview() {
            this.annualReportData.yearlyOverview = await Api.getMultiYearOverview(this.annualReportId);
            this.$waiting.end("loading-multiYearOverView");
        },
        async getValidatedChangesInEquity() {
            this.annualReportData.changesInEquity = await Api.getChangesInEquity(this.annualReportId);
            this.$waiting.end("loading-changeInEquity");
        },
        async getValidatedProfitDisposition() {
            this.annualReportData.incomeStatementAndProfitDistributions = await Api.getProfitDisposition(this.annualReportId);
            this.$waiting.end("loading-profitDisposition");
        },
    },
};
</script>

<style>
.custom-textarea .el-textarea__inner:disabled {
    color: #444a55;
    border-color: #aaaaaa;
    background-color: #eeeeee;
    font-size: 14px;
}
.custom-textarea {
    font-size: 14px;
}
.round-button,
.round-button:active,
.round-button:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #415770;
    font-size: x-large;
}
.round-button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #6e85a0;
    font-size: x-large;
}
.save-button {
    background-color: #48c78e;
    border-color: #48c78e;
    color: #ffffff;
}
.save-button:hover {
    background-color: #5bd6a3;
    color: #ffffff;
}
.tooltip-content {
    width: 500px;
    padding: 5px;
    border: none;
}
.management-report-card-box {
    margin-top: 40px;
    max-width: fit-content;
    min-width: 800px;
    overflow: auto;
}
</style>
